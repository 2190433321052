import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Grid } from '@mui/material';
import Footer from '../components/Footer';

function ContactPage() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: ''
  });

  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!formData.first_name || !formData.last_name || !formData.email || !formData.message) {
      setSubmitStatus('Please fill out all fields.');
      return;
    }

    setSubmitStatus('Submitting...');

    try {
      await fetch('https://vd4p2yr8f9.execute-api.eu-west-2.amazonaws.com/prod/form-submission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      setSubmitStatus('Thank you for your submission. We will be in touch.');
      setFormData({ first_name: '', last_name: '', email: '', message: '' }); // Clear the form fields
    } catch (error) {
      console.error('Error:', error); // Log the error for debugging purposes
      setSubmitStatus('Thank you for your submission. We will be in touch.');
      setFormData({ first_name: '', last_name: '', email: '', message: '' }); // Clear the form fields even if an error occurs
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh', 
        backgroundImage: `url('https://webpfilesits.s3.eu-west-2.amazonaws.com/getty5.webp')`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white' 
      }}
    >
      <Container 
        maxWidth="md" 
        sx={{ 
          flex: '1 0 auto', 
          position: 'relative', 
          overflow: 'hidden', 
          pt: 4, 
          mt: 4,  
          mb: 4,  
          borderRadius: 2,  
          boxShadow: 3,
          backgroundColor: 'rgba(255, 255, 255, 0.9)', 
          color: 'black' 
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 1, my: 4 }}>
          <Typography variant="h2" gutterBottom style={{ fontWeight: 'bold', mb: 4 }}>
            GET IN TOUCH
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            We can help you build a successful sustainable career as an athlete.
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Contact Details
              </Typography>
              <Typography variant="body2">SA: +27 (0) 12 342 0643</Typography>
              <Typography variant="body2">UK: +44 (0) 207 924 6780</Typography>
              <Typography variant="body2">office@intouchsports.com</Typography>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 6, fontWeight: 'bold' }}>
                Office Hours
              </Typography>
              <Typography variant="body2">Monday – Friday</Typography>
              <Typography variant="body2">9am – 5pm</Typography>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 6, fontWeight: 'bold' }}>
                Address
              </Typography>
              <Typography variant="body2">In Touch Sports</Typography>
              <Typography variant="body2">Loftus Versfeld Rugby Stadium</Typography>
              <Typography variant="body2">South Pavilion</Typography>
              <Typography variant="body2">Lynnwood Rd</Typography>
              <Typography variant="body2">2nd Floor, Office 4</Typography>
              <Typography variant="body2">Sunnyside, Pretoria, 0002</Typography>
              <Typography variant="body2">South Africa</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField 
                  required 
                  fullWidth 
                  label="First Name" 
                  margin="normal" 
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <TextField 
                  required 
                  fullWidth 
                  label="Last Name" 
                  margin="normal" 
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                <TextField 
                  required 
                  fullWidth 
                  label="Email" 
                  margin="normal" 
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  required
                  fullWidth
                  label="Comment or Message"
                  multiline
                  rows={4}
                  margin="normal"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                <Button variant="contained" sx={{ mt: 2 }} fullWidth type="submit">
                  Submit
                </Button>
              </Box>
              {submitStatus && (
                <Typography variant="body2" sx={{ mt: 2, color: 'green' }}>
                  {submitStatus}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box sx={{ flexShrink: 0 }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default ContactPage;